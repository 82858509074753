<template>
  <div id="sing-in-view" class="sing-in-view">
    <div class="sing-in-view__content" :action="userSignInAction">
      <!-- section | white | left -->
      <div class="section section--white" v-show="userSignInAction === 'sign-in'">
        <div class="section--white__inner">
          <div class="sing-in-view__logo">
            <img 
              @click="$router.push('/')" 
              src="../assets/logo.png"
            >
          </div>
          <div class="section--white__inner__content">
            <div class="section--white__title">
              {{$localize.get('sign_in_to_account')}}
            </div>
            <v-form ref="signInForm">
              <div class="text-field-component">
                <div class="text-field-label">{{$localize.get('email_address')}}*</div>
                <v-text-field
                  v-model="inputs.email"
                  :rules="isValidEmail(inputs.email)"
                  :placeholder="$localize.get('enter_email')"
                  type="email"
                  outlined
                  required
                ></v-text-field>
              </div>
              <div class="text-field-component">
                <div class="text-field-label">{{$localize.get('password')}}*</div>
                <v-text-field
                  v-model="inputs.password"
                  :rules="isValidPassword(inputs.password)"
                  :placeholder="$localize.get('enter_password')"
                  :type="inputs.showPassword ? 'text' : 'password'"
                  outlined
                  required
                  append-icon="mdi-eye-outline"
                  @click:append="toggleShowPassword"
                ></v-text-field>
              </div>
            </v-form>
            <div class="section--white__password-actions">
              <div>
                <v-checkbox
                  :label="$localize.get('remember_me')"
                  v-model="inputs.rememberMe"
                >
                </v-checkbox>
              </div>
              <div>
                <h-button text small no-padding class="section--white__password-actions__forgot-password-btn">
                  {{$localize.get('forgot_password')}}
                </h-button>
              </div>
            </div>
            <div>
              <h-button
                @click.native="signUpBtn()"
                large green filled block
              >{{$localize.get('sign_in')}}
              </h-button>
            </div>
            <div class="section--white__extra">
              <div class="section--white__extra__title">
                {{$localize.get('dont_have_account')}}
              </div>
              <div class="section--white__extra__sign-in-buttons">
                <h-button
                  @click.native="signUpSwitchBtn()"
                  large blue outlined block
                >{{$localize.get('sign_up')}}
                </h-button>
              </div>
            </div>
          </div>
          <div class="section--white__extra__legal-buttons">
            <h-button
              text blue no-padding
            >{{$localize.get('privacy_policy')}}
            </h-button>
            <h-button
              text blue no-padding
            >{{$localize.get('terms_of_use')}}
            </h-button>
          </div>
        </div>
      </div>
      <!-- section | blue | right -->
      <div class="section section--blue" v-show="userSignInAction === 'sign-in'">
        <div class="section--blue__inner">
          <div class="section--blue__inner__content">
            <div class="section--blue__title" :action="userSignInAction">
              {{$localize.get('dont_have_account')}}
            </div>
            <div class="section--blue__subtitle">
              {{$localize.get('fill_up_personal_info')}}
            </div>
            <div class="section--blue__button">
              <h-button
                @click.native="signUpSwitchBtn()"
                large white outlined block
              >{{$localize.get('sign_up')}}
              </h-button>
            </div>
          </div>
        </div>
      </div>
      <!-- section | blue | left -->
      <div class="section section--blue" v-show="userSignInAction === 'sign-up'">
        <div class="section--blue__inner">
          <div class="sing-in-view__logo">
            <img 
              @click="$router.push('/')" 
              src="../assets/logo-white.png"
            >
          </div>
          <div class="section--blue__inner__content">
            <div class="section--blue__title">
              {{$localize.get('already_signed_up')}}
            </div>
            <div 
              class="section--blue__subtitle" 
              v-html="$localize.get('if_you_already_have_account')"
            ></div>
            <div class="section--blue__button">
              <h-button
                @click.native="signInSwitchBtn()"
                large white outlined block
              >{{$localize.get('sign_in')}}
              </h-button>
            </div>
          </div>
        </div>
      </div>
      <!-- section | white | right -->
      <div class="section section--white" v-show="userSignInAction === 'sign-up'">
        <div class="section--white__inner">
          <div class="section--white__inner__content">
            <div class="section--white__title">
              {{$localize.get('sign_up_for_an_account')}}
            </div>
            <div class="section--white__subtitle">
              {{$localize.get('choose_type_of_account_you_need')}}*
            </div>
            <div class="section--white__cards">
              <card 
                @click.native="user.signIn.type = 'client'"
                :is-active="user.signIn.type === 'client'"
                selector 
              >
                <template v-slot:preview>
                  <img v-if="user.signIn.type === 'client'" src="../assets/client-white.svg">
                  <img v-else src="../assets/client-blue.svg">
                </template>
                <template v-slot:title>
                  {{$localize.get('i_am_a_client')}}
                </template>
                <template v-slot:subtitle>
                  {{$localize.get('i_need_specialists')}}
                </template>
              </card>
              <card 
                @click.native="user.signIn.type = 'team'"
                :is-active="user.signIn.type === 'team'"
                selector 
              >
                <template v-slot:preview>
                  <img v-if="user.signIn.type === 'team'" src="../assets/team-white.svg">
                  <img v-else src="../assets/team-blue.svg">
                </template>
                <template v-slot:title>
                  {{$localize.get('we_are_a_team')}}
                </template>
                <template v-slot:subtitle>
                  {{$localize.get('we_have_specialists')}}
                </template>
              </card>
            </div>
            <v-form ref="signUpForm" v-model="inputs.signUpFormIsValid">
              <div class="text-field-component">
                <div class="text-field-label">{{$localize.get('email_address')}}*</div>
                <v-text-field
                  v-model="inputs.email"
                  :rules="isValidEmail(inputs.email)"
                  :placeholder="$localize.get('enter_email')"
                  type="email"
                  outlined
                  required
                ></v-text-field>
              </div>
              <div class="text-field-component">
                <div class="text-field-label">{{$localize.get('create_password')}}*</div>
                <v-text-field
                  v-model="inputs.password"
                  :rules="isValidPassword(inputs.password)"
                  :placeholder="$localize.get('create_password')"
                  :type="inputs.showPassword ? 'text' : 'password'"
                  outlined
                  required
                  append-icon="mdi-eye-outline"
                  @click:append="toggleShowPassword"
                ></v-text-field>
              </div>
              <v-checkbox
                class="mt-0 mb-4"
                v-model="inputs.agreeToTerms"
                :rules="areTermsAccepted()"
                required
              >
                <template v-slot:label>
                  {{$localize.get('i_agree_to')}}
                  <span 
                    class="link ml-2" 
                    @click.prevent.stop="openTermsAndConditions()"
                  >{{$localize.get('terms_and_conditions')}}
                  </span>
                </template>
              </v-checkbox>
            </v-form>
            <div>
              <h-button
                @click.native="signUpBtn()"
                large green filled block
              >{{$localize.get('sign_up')}}
              </h-button>
            </div>
            <div class="section--white__extra">
              <div class="section--white__extra__title">
                {{$localize.get('already_signed_up')}}
              </div>
              <div class="section--white__extra__sign-in-buttons">
                <h-button
                  @click.native="signInSwitchBtn()"
                  large blue outlined block
                >{{$localize.get('sign_in')}}
                </h-button>
              </div>
            </div>
          </div>
          <div class="section--white__extra__legal-buttons">
            <h-button
              text blue no-padding
            >{{$localize.get('privacy_policy')}}
            </h-button>
            <h-button
              text blue no-padding
            >{{$localize.get('terms_of_use')}}
            </h-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignIn',
  data () {
    return {
      inputs: {
        email: '',
        password: '',
        rememberMe: false,
        agreeToTerms: false,
        showPassword: false,
        signUpFormIsValid: true
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    userSignInAction: {
      get () {
        return this.$store.state.user.signIn.action
      },
      set (value) {
        this.$store.state.user.signIn.action = value
      }
    },
  },
  methods: {
    signInSwitchBtn () {
      this.userSignInAction = 'sign-in'
    },
    signInBtn () {
      this.user.loggedIn = true
      this.$router.push('profile').catch(error => {})
    },
    signUpSwitchBtn () {
      this.userSignInAction = 'sign-up'
    },
    signUpBtn () {
      this.$refs.signUpForm.validate()
      this.$refs.signInForm.validate()
      // this.user.loggedIn = true
      // this.$router.push('profile').catch(error => {})
    },
    isValidEmail (value) {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return [
        value => !!value || this.$localize.get('email_is_required'),
        value => value.length === 0 || emailRegex.test(value) || this.$localize.get('enter_valid_email'),
      ]
    },
    isValidPassword (value) {
      return [
        value => !!value || this.$localize.get('password_is_required'),
        value => value.length > 10 || this.$localize.get('choose_stronger_password'),
      ]
    },
    areTermsAccepted (value) {
      return [
        value => !!value || this.$localize.get('have_to_agree_to_terms')
      ]
    },
    openTermsAndConditions () {
      console.log('terms')
    },
    toggleShowPassword () {
      this.inputs.showPassword = !this.inputs.showPassword
    }
  }
}
</script>

<style lang="scss">
.text-field-component {
  &__inner {
    position: relative;
  }
  .text-field {
    position: relative;
    display: flex;
    width: 100%;
    height: 64px;
    padding: 0 30px;
    border: 1px solid #8692A6;
    box-sizing: border-box;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    &[error] {
      border: 1px solid #F93043;
    }
    &::placeholder {
      color: var(--grey-2);
      transition: opacity 0.3s;
    }
    &:focus {
      outline: none;
      border: 1px solid #0051EE;
      box-sizing: border-box;
      box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.08);
      &::placeholder {
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
  }
  .text-field-label {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--grey-3);
    margin-bottom: 8px;
  }
}

.sing-in-view {
  $breakpoint-single-row: 1400px;
  --headerHeight: 100px;
  position: relative;
  &__logo {
    img {
      width: 200px;
      cursor: pointer;
    }
    @media (max-width: $breakpoint-single-row) {
      display: none;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    &[action="sign-up"] {
      .section--white {
        &__inner {
          align-self: center;
          display: grid;
          grid-template-rows: 1fr 36px;
          height: 100%;
          @media (max-width: $breakpoint-single-row) {
            align-self: unset;
            margin-top: 32px;
          }
          @media (max-width: $breakpoint-single-row) {
            display: block;
          }
        }
      }
    }
    &[action="sign-in"] {
      grid-template-columns: 1.3fr 1fr;
      // flex-direction: row-reverse;
        @media (max-width: $breakpoint-single-row) {
          display: block;
        }
      .section--blue {
        transition: all 1s ease;
        // transform: translateX(130%);
        &__inner {
          align-self: center;
        }
      }
      .section--white {
        transition: all 1s ease;
        // transform: translateX(-76%);
        box-shadow: 16px 0px 24px rgba(10, 10, 10, 0.16);
        @media (max-width: $breakpoint-single-row) {
          width: 100vw;
          box-shadow: none;
        }
        &__inner {
          max-width: 600px;
          width: 100%;
          display: grid;
          grid-template-rows: var(--headerHeight) 1fr;
          @media (max-width: $breakpoint-single-row) {
            display: block;
            margin-top: 32px;
          }
        }
      }
    }
    .section {
      display: flex;
      // align-items: center;
    }
    .section--blue {
      min-height: 100vh;
      background-color: var(--blue-4);
      @media (max-width: $breakpoint-single-row) {
        display: none;
      }
      &__inner {
        min-width: 600px;
        padding: var(--headerHeight) 15%;
        display: grid;
        grid-template-rows: var(--headerHeight) 1fr;
        &__content {
          align-self: center;
        }
      }
      &__button {
        width: 320px;
      }
      &__title {
        font-family: 'Proxima Nova Bl';
        font-weight: 800;
        font-size: 30px;
        line-height: 161%;
        letter-spacing: 0.04em;
        color: #fff;
      }
      &__subtitle {
        max-width: 320px;
        font-weight: 600;
        font-size: 18px;
        line-height: 161%;
        color: #fff;
        margin-top: 64px;
        margin-bottom: 32px;
      }
    }
    .section--white {
      display: flex;
      min-height: 100vh;
      background-color: #fff;
      box-shadow: -16px 0px 24px rgba(10, 10, 10, 0.16);
      @media (max-width: $breakpoint-single-row) {
        width: 100vw;
        box-shadow: none;
      }
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: var(--grey-3);
        @media (max-width: $breakpoint-single-row) {
        }
      } 
      &__title {
        font-family: 'Proxima Nova Bl';
        margin-bottom: 36px;
      }
      &__inner {
        width: 100%;
        width: 600px;
        padding: var(--headerHeight) 15%;
        @media (max-width: $breakpoint-single-row) {
          display: block;
          width: 100%;
          padding: 0 10%;
        }
        &__content {
          align-self: center;
          width: 600px;
          margin-bottom: 64px;
          @media (max-width: $breakpoint-single-row) {
            width: 100%;
          }
        }
      }
      &__password-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .v-input--selection-controls {
          margin-top: 0;
          padding-top: 12px;
          .v-input__slot {
            margin: 0;
          }
        }
        &__forgot-password-btn {
          font-family: 'Proxima Nova Lt';
          font-weight: 500;
          font-size: 18px;
          color: #555;
          @media (max-width: $breakpoint-single-row) {
            font-family: 'Proxima Nova';
            color: var(--blue-main);
            font-size: 16px;
          }
        }
        .v-input--selection-controls .v-input__slot > .v-label {
          font-family: 'Proxima Nova Lt';
          font-weight: 500;
          font-size: 18px;
          color: #555;
          @media (max-width: $breakpoint-single-row) {
            font-family: 'Proxima Nova';
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
      &__title {
        font-weight: 800;
        font-size: 30px;
        line-height: 161%;
        letter-spacing: 0.04em;
        color: var(--blue-main);
        @media (max-width: $breakpoint-single-row) {
          font-size: 32px;
          line-height: 115%;
          text-align: center;
          letter-spacing: -0.065em;
        }
      }
      &__subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: var(--grey-3);
        margin-top: 48px;
        margin-bottom: 16px;
        @media (max-width: $breakpoint-single-row) {
          margin-top: 32px;
        }
      }
      &__cards {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        @media (max-width: 480px) {
          display: block; 
        }
        .card {
          width: 100%;
          &:first-child {
            margin-right: 20px;
            @media (max-width: 480px) {
              margin-bottom: 20px;
            }
          }
        }
      }
      &__extra {
        display: none;
        @media (max-width: $breakpoint-single-row) {
          display: block;
        }
        &__title {
          text-align: center;
          margin-top: 48px;
          margin-bottom: 16px;
          font-family: 'Proxima Nova Rg';
        }
        &__sign-in-buttons {
          margin-top: 24px;
          margin-bottom: 48px;
        }
        &__legal-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.6;
          @media (max-width: $xs) {
            flex-direction: column;
          }
          .h-button:first-child {
            margin-right: 24px;
            @media (max-width: $xs) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>